// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "21.1.0",
  "buildNumber": "12439",
  "gitSha": "5a4959c7c4ede49165ec961b0219126cd5aa9e52",
  "fullVersion": "21.1.0-12439",
  "formattedVersion": "21.1.0 (12439-5a4959c7c4ede49165ec961b0219126cd5aa9e52)",
  "copyrightYear": "2021"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/21.1.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/21.1.0/${stripLeadingPrefix(suffix, '#')}`
}
    
